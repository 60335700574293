import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@fuww/library/src/Grid';
import NewsCardWithLink from '../../CardWithLink';
import loadMore from '../../../lib/queries/loadMore';
import LoadMore from '../../LoadMore';

const ArticleFeed = ({
  variables,
  fetchMore,
  data,
  loading,
  preloadFirst,
}) => (
  <>
    {data.universalNewsArticlesConnection
      .edges
      .map(({ node: newsArticle }, index) => {
        const isFirstImage = index === 0;
        const fetchPriority = (preloadFirst && isFirstImage) ? 'high' : 'low';

        return (
          <GridCell
            sm={6}
            lg={3}
            key={newsArticle.id}
            padding="16px"
            align="top"
          >
            <NewsCardWithLink
              newsArticle={newsArticle}
              variables={variables}
              preload={preloadFirst && isFirstImage}
              finalFetchpriority={fetchPriority}
              placeholderFetchpriority={fetchPriority}
              withPageMargins
            />
          </GridCell>
        );
      })}
    <GridCell padding="16px">
      <LoadMore
        loading={loading}
        hasNextPage={
          data.universalNewsArticlesConnection
            .pageInfo
            .hasNextPage
        }
        fetchMore={
          () => loadMore(
            { variables },
          )(data, 'universalNewsArticlesConnection', fetchMore)
        }
      />
    </GridCell>
  </>
);

ArticleFeed.propTypes = {
  fetchMore: PropTypes.func,
  variables: PropTypes.shape({}),
  data: PropTypes.shape({
    universalNewsArticlesConnection: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({}).isRequired,
      })).isRequired,
      pageInfo: PropTypes.shape({
        hasNextPage: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  loading: PropTypes.bool.isRequired,
  preloadFirst: PropTypes.bool,
};

ArticleFeed.defaultProps = {
  fetchMore: undefined,
  variables: undefined,
  data: null,
  preloadFirst: false,
};

export default ArticleFeed;
